
import {defineComponent, reactive, ref, toRefs} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import {useI18n} from "vue-i18n";

export default defineComponent({
  name: "sign-up",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    const submitButton = ref<HTMLButtonElement | null>(null);
    const state = reactive({
      date_of_birth: '',
    })
    const registration = Yup.object().shape({
      first_name: Yup.string().required(`${t('is_required')}`).label(`${t('first_name')}`),
      last_name: Yup.string().required(`${t('is_required')}`).label(`${t('last_name')}`),
      email: Yup.string().min(4).required(`${t('is_required')}`).email().label(`${t('email')}`),
      phone: Yup.string().min(8).required(`${t('is_required')}`).label(`${t('phone')}`),
      toc: Yup.bool().required(`${t('is_required')}`).label(`${t('phone')}`),
      password: Yup.string().min(4).required(`${t('is_required')}`).label(`${t('Password')}`),
      password_confirmation: Yup.string()
        .min(4)
        .required(`${t('is_required')}`)
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .label(`${t('confirm_password')}`),
    });

    const onSubmitRegister = (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      // eslint-disable-next-line
      submitButton.value!.disabled = true;

      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      values.date_of_birth = state.date_of_birth;
      values.locale = window.localStorage.getItem('lang');
      // Dummy delay
      setTimeout(() => {
        // Send login request
        store
          .dispatch(Actions.REGISTER, values)
          .then(() => {
            store.dispatch(Actions.GET_USER)
            store.getters.getUserInfoAll
            Swal.fire({
              text: t('successfuly_created_account'),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t('ok'),
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              // Go to page after successfully login
              //router.push({ name: "dashboard" });
              window.location.href="/";
            });
          })
          .catch(() => {
            const error = Object.values(store.getters.getErrors.error);
            Swal.fire({
              html: error.map(item => `<p>${item}</p>`)
                  .join(''),
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        submitButton.value?.removeAttribute("data-kt-indicator");
        // eslint-disable-next-line
        submitButton.value!.disabled = false;
      }, 2000);
    };

    return {
      registration,
      onSubmitRegister,
      submitButton,
      ...toRefs(state),
    };
  },
  methods: {
    authProvider(provider) {
      window.location.href=process.env.VUE_APP_API_URL+"/login/"+provider;
    }
  }
});
